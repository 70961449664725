import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DownloadIcon from '@/assets/icons/domains/finance-admin/DownloadIcon.vue';
import PayoutDetailsIcon from '@/assets/icons/domains/finance-admin/PayoutDetailsIcon.vue';
import LinkIcon from '@/assets/icons/domains/finance-admin/LinkIcon.vue';
import FilterIcon from '@/assets/icons/domains/finance-admin/FilterIcon.vue';
import {
  ZidLoader,
  ZidButton,
  ZidTextarea,
  ZidPageHeader,
  ZidIcon,
  ZidTable,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
  ZidTableCell,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidCheckbox,
  ZidCombo,
  ZidComboItem,
  ZidInput,
  ZidDatepicker,
  ZidPagination,
  ZidModal,
  ZidSwitch,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidFileSelector,
} from '@zidsa/ui';
import usePayouts from '../../helpers/usePayouts';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidLoader,
    ZidButton,
    ZidTextarea,
    ZidPageHeader,
    ZidIcon,
    ZidTable,
    ZidTableHeader,
    ZidTableRow,
    ZidTableRowGroup,
    ZidTableCell,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidCheckbox,
    ZidCombo,
    ZidComboItem,
    ZidInput,
    ZidModal,
    ZidDatepicker,
    ZidPagination,
    ZidSwitch,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidFileSelector,
    DownloadIcon,
    PayoutDetailsIcon,
    LinkIcon,
    FilterIcon,
  },
  setup() {
    return {
      ...usePayouts(),
    };
  },
});
