import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerListResponseModel from '@/api/models/PartnerListResponseModel';
import PartnerAppsListResponseModel from '@/api/models/PartnerAppsListResponseModel';
import PartnerThemesListResponseModel from '@/api/models/PartnerThemesListResponseModel';
import AllPayoutsResponseModel from '../models/AllPayoutsResponseModel';
import { PayoutsFilterParamsType, PayoutsFilterParamsInterface } from '../types/payouts.type';

const ENDPOINT_URL = '/pd-finance';

export const getPartnersList = (transactionType: number): Promise<PartnerListResponseModel | ErrorModel> => {
  const params = { transaction_type: transactionType };

  return httpClient
    .get(`${ENDPOINT_URL}/partners/list-having-transactions`, { params })
    .then((response) => new PartnerListResponseModel(response))
    .catch((error) => error);
};

export const getApplicationsList = (partners?: number[]): Promise<PartnerAppsListResponseModel | ErrorModel> => {
  const params = partners && partners.length > 0 ? { partner_id: partners } : {};

  return httpClient
    .get(`${ENDPOINT_URL}/apps/list`, { params })
    .then((response) => new PartnerAppsListResponseModel(response))
    .catch((error) => error);
};

export const getThemesList = (partners?: number[]): Promise<PartnerThemesListResponseModel | ErrorModel> => {
  const params = partners && partners.length > 0 ? { partner_id: partners } : {};

  return httpClient
    .get(`${ENDPOINT_URL}/themes/list`, { params })
    .then((response) => new PartnerThemesListResponseModel(response))
    .catch((error) => error);
};

export const getPayoutList = (filters: PayoutsFilterParamsType): Promise<AllPayoutsResponseModel | ErrorModel> => {
  const filterParams: PayoutsFilterParamsInterface = {};

  if (filters.searchTerm) {
    filterParams.code = filters.searchTerm;
  }
  if (filters.partner.length) {
    filterParams.partner_id = [...filters.partner];
  }
  if (filters.item.length) {
    filterParams.item = [...filters.item];
  }
  if (filters.status.length) {
    filterParams.status = [...filters.status];
  }
  if (filters.transaction_type) {
    filterParams.transaction_type = filters.transaction_type;
  }
  if (filters.date) {
    filterParams.date = filters.date;
  }
  if (filters.resultPerPage) {
    filterParams.per_page = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.page = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}/payouts`, {
      params: filterParams,
    })
    .then((response) => new AllPayoutsResponseModel(response))
    .catch((error) => error);
};

export const exportPayoutDetails = (id: string): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/payouts/export`, { id }, { responseType: 'blob' })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const downloadPayoutInvoice = (id: string): Promise<Blob | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/payouts/${id}/invoice`, { responseType: 'blob' })
    .then((response) => response)
    .catch((error) => error);
};

export const downloadPayoutReceipt = (id: string): Promise<Blob | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/payouts/${id}/receipt`, { responseType: 'blob' })
    .then((response) => response)
    .catch((error) => error);
};

export const submitPayoutInvoice = (id: string, invoiceFile: File): Promise<BaseModel | ErrorModel> => {
  const formData = new FormData();
  formData.append('invoice_file', invoiceFile);

  return httpClient
    .post(`${ENDPOINT_URL}/payouts/${id}/invoice`, formData)
    .then((response) => response)
    .catch((error) => error);
};

export const submitPayoutReceipt = (id: string, receiptFile: File): Promise<BaseModel | ErrorModel> => {
  const formData = new FormData();
  formData.append('receipt_file', receiptFile);

  return httpClient
    .post(`${ENDPOINT_URL}/payouts/${id}/receipt`, formData)
    .then((response) => response)
    .catch((error) => error);
};

export const updatePayoutStatus = (id: string, status: number, note?: string): Promise<BaseModel | ErrorModel> => {
  const params = { status, ...(note !== undefined && { rejection_notice: note }) };
  return httpClient
    .put(`${ENDPOINT_URL}/payouts/${id}/change-status`, params)
    .then((response) => response)
    .catch((error) => error);
};
