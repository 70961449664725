import BaseModel from './BaseModel';

class PartnerListResponseModel extends BaseModel {
  public partners: { id: number; name: string }[];

  constructor(arg: any) {
    super(arg);
    this.partners = arg.partners;
  }
}

export default PartnerListResponseModel;
