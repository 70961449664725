import BaseModel from '@/api/models/BaseModel';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';
import { PayoutItemType } from '../types/payouts.type';

class AllPayoutsResponseModel extends BaseModel {
  public payload: {
    payouts: PayoutItemType[];
    pagination: LengthAwarePaginationInterface;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default AllPayoutsResponseModel;
