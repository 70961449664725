export enum PayoutTypeLabelsEnum {
  PublicOrPrivateApps = 'Public/Private Apps',
  ShippingApps = 'Shipping Apps',
  Themes = 'Themes',
}

export enum PayoutTypeValuesEnum {
  PublicOrPrivateApps = 1,
  ShippingApps = 2,
  Themes = 3,
}

export interface ApplicationInterface {
  label: string;
  value: number;
  type: number;
}
export interface NumericFieldValueInterface {
  label: string;
  value: number;
}

export type ManagePayoutListState = {
  searchValue: string;
  selectedResultPerPage: Record<string, string>;
  settlementStatus: SettlementStatusLabelsEnum[];
  app: number[];
  theme: number[];
  partner: number[];
  payoutDate: string;
  targetPage: number;
};

export type ManagePayoutDetailsState = {
  payoutStartDate: string;
  payoutEndDate: string;
  newInvoiceFile: File | null;
  newReceiptFile: File | null;
  hasInvoice: boolean;
  hasReceipt: boolean;
  canUploadInvoice: boolean;
  canUploadReceipt: boolean;
  paymentStatus: number | null;
  rejectionNote: string | null;
};

export enum ManagePayoutSearchProperty {
  SearchValue = 'searchValue',
}

export type PayoutItemType = {
  id: string;
  code: string;
  date: string;
  item_name: string;
  partner_name: string;
  recipient: number;
  rejection_notice: string | null;
  partner_share?: string;
  zid_share?: string;
  activations_fees?: string;
  orders_fees?: string;
  withholding_taxes: string;
  payment_status: number;
  can_upload_invoice: boolean;
  can_upload_receipt: boolean;
  has_invoice: boolean;
  has_receipt: boolean;
  signup_fee_zid_share?: number;
  signup_fee_partner_share?: number;
};

export type PayoutsListType = {
  list: PayoutItemType[];
};

export enum SettlementStatusLabelsEnum {
  Pending = 'Pending',
  InReview = 'In Review',
  PaymentRequired = 'Payment Required',
  Rejected = 'Rejected',
  Settled = 'Settled',
  ReceiptUploaded = 'Receipt Uploaded',
}

export enum SettlementStatusValuesEnum {
  Pending = 1,
  InReview = 2,
  PaymentRequired = 3,
  Rejected = 4,
  Settled = 5,
  ReceiptUploaded = 6,
}

export type PayoutsFilterParamsType = {
  searchTerm: string;
  partner: number[];
  item: number[];
  status: number[];
  transaction_type: number;
  date: string;
  targetPage: number;
  resultPerPage: string | number;
};

export interface PayoutsFilterParamsInterface {
  code?: string;
  partner_id?: number[];
  item?: number[];
  status?: number[];
  transaction_type?: number;
  date?: string;
  page?: number;
  per_page?: string | number;
}
