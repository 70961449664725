import BaseModel from './BaseModel';
import { PayoutTypeValuesEnum } from '@/domains/payouts/types/payouts.type';

class PartnerAppsListResponseModel extends BaseModel {
  public apps: { id: number; name: string; type: PayoutTypeValuesEnum }[];

  constructor(arg: any) {
    super(arg);
    this.apps = arg.apps;
  }
}

export default PartnerAppsListResponseModel;
